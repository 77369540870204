import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="D" transform="translate(11.000000, 5.000000)">
        <path
          d="m29.1 61v-30.6h8.5q8.9 0 13.1 3.7 4.2 3.7 4.2 11.6 0 7.9-4.2 11.6-4.2 3.7-13.1 3.7zm9.2-27.2h-5v23.8h5q6.3 0 9.2-2.9 3-2.8 3-9 0-6.2-3-9-2.9-2.9-9.2-2.9z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
